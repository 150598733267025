<template>
  <div class="form--group" :class="{ 'has-error': validation.hasError('selectedRentPeriodIds') }">
    <div class="left--col">
      <label>
        <span>{{ $t('addListing.rentPrice') }}</span>
        <span class="required">*</span>
      </label>
    </div>
    <div class="right--col">
      <rent-price-field
        v-for="(rentPeriod, index) in rentPeriods"
        :key="index"
        :index="index"
        :id="rentPeriod.id"
        :name="rentPeriod.name"
        :price="rentPeriod.price"
        :checked="rentPeriod.checked"
        ref="rentPricesRef"
      />
      <span class="val-error" v-if="validation.hasError('selectedRentPeriodIds')">{{
        validation.firstError('selectedRentPeriodIds')
      }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Validator } from 'simple-vue-validator';
const RentPriceField = () =>
  import('@/components/listing-form/sect1/rent-price/rent-price-field.vue');

export default {
  name: 'rent-prices-section',
  components: {
    RentPriceField,
  },
  computed: {
    ...mapGetters({
      selectedRentPeriodIds: 'v2/listingForm/sect1/basicInfo/selectedRentPeriodIds',
      rentPeriods: 'v2/listingForm/sect1/basicInfo/rentPeriodList',
    }),
    ...mapState({
      listingType: state => state.v2.listingForm.listingType,
      viewToOffer: state => state.v2.listingForm.sect1.basicInfo.viewToOffer,
    }),
  },
  validators: {
    selectedRentPeriodIds(value) {
      if (this.listingType === 'S' || this.viewToOffer) return null;
      return Validator.value(value).custom(() => {
        if (value.length <= 0) {
          return this.$i18n.t('errors.addListing.rentPrice.required');
        }
      });
    },
  },
  methods: {
    validate() {
      let validationArray = this.$refs.rentPricesRef.map(function(form) {
        return form.validate();
      });
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function(results) {
        return (
          results.filter(function(result) {
            console.log('mana yg salah', result);
            return !result;
          }).length === 0
        );
      });
    },
  },
};
</script>

<style scoped></style>
